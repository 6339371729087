import React from 'react'
import PropTypes from 'prop-types'
import Img from '../../../../../../src/components/shared/Img'
import Hyperlink from '../../../HyperLink'
import { generateElementId } from '../../../../../common/helpers'
import SvgIcon from '../../../SvgIcon'

const RecommendedReadsItem = ({
  img: { src, alt },
  title,
  subtitle,
  description,
  link,
  isMobile,
  isFullSizeMobile,
}) => (
  <div
    className={`gap-primary bg-inherit grid rounded-md lg:grid-cols-12 lg:border lg:bg-white lg:p-8`}
    id={`recommended-reads-item-${generateElementId(title)}`}
    data-testid="recommended-reads-item"
    style={{ marginLeft: isMobile && isFullSizeMobile ? '0' : '1rem' }}
  >
    <Img
      src={src}
      alt={alt}
      className="lg:col-span-6"
      imgClassName="img-radius"
      layout="responsive"
      objectFit="cover"
      styles={{ position: 'relative' }}
    />
    <div className="my-auto pt-4 lg:col-span-5 lg:pt-0">
      <div className="lg:min-h-40">
        <p className="text-sm text-gray-940">{title}</p>
        <Hyperlink href={link} title={title}>
          <p className="min-h-25 pt-4 text-2xl font-medium text-black-base sm:min-h-20 lg:min-h-0">
            {subtitle}
          </p>
        </Hyperlink>
        {description ? (
          <p className="mt-4 text-15 text-black-base-light">{description}</p>
        ) : null}
      </div>
      <Hyperlink
        childrenClassNames="flex pt-5 text-15 font-bold leading-7 text-blue-base lg:pt-0 lg:text-lg"
        href={link}
        title={title}
      >
        Learn&nbsp;more
        <SvgIcon
          name="chevronThinRight"
          className="my-auto ml-1.5 h-5 w-5 fill-blue-base"
        />
      </Hyperlink>
    </div>
  </div>
)

RecommendedReadsItem.defaultProps = {
  description: '',
}

RecommendedReadsItem.propTypes = {
  img: PropTypes.shape({
    src: PropTypes.string.isRequired,
    alt: PropTypes.string.isRequired,
  }),
  title: PropTypes.string.isRequired,
  subtitle: PropTypes.string.isRequired,
  description: PropTypes.string,
  link: PropTypes.string.isRequired,
}

export default RecommendedReadsItem
