import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import Image from 'next/image'
import RichText from '../../../RichText'
import PrimaryButton from 'src/components/shared/PrimaryButton'
import ProvenResultList from './ProvenResultList'
import { useLeadId } from '../../../../../common/lead-id'
import useOnViewport from '../../../../../common/hooks/useOnViewport'
import { APPLY_FDR_URL } from '../../../../../common/constants'
import { useRouter } from 'next/router'
import { buildQueryString } from '../../../../../common/utils'
import TrustpilotItem from '../../../TrusPilotItem'
import { ProvenResultsBelowTheFold } from '../../../ProvenResults'

const HeroSection = (props) => {
  const {
    title,
    description,
    img,
    provenResult,
    btnName,
    btnUrl,
    showTrustpilot,
    provenResultsBelowTheFold,
    phone = '',
    heroContainerClassName = '',
    contentClassName = '',
    provenResultsClassName = '',
    imgClassName = '',
    imgContainerClassName = '',
    richTextClassName = '',
  } = props

  const [isHeroCardVisible, isCtaButtonUnderViewport] = useOnViewport(
    'heroPrimaryBtnContent'
  )

  const showStickyBtn = useMemo(
    () => !isHeroCardVisible && !isCtaButtonUnderViewport,
    [isHeroCardVisible, isCtaButtonUnderViewport]
  )

  const leadId = useLeadId()
  const { query: baseQuery } = useRouter()

  let query = useMemo(
    () => buildQueryString(baseQuery, leadId),
    [baseQuery, leadId]
  )

  return (
    <>
      <div
        id="hero-card"
        className={`${heroContainerClassName} gap-x-primary relative grid grid-cols-12`}
      >
        <div
          className={`shadow-transparent absolute z-50 col-span-full my-auto flex flex-col justify-center justify-self-center rounded-xl bg-white p-5 text-center shadow-gray-400 drop-shadow-xl lg:relative lg:col-span-6 lg:m-0 lg:rounded-none lg:bg-none lg:p-0 lg:text-left lg:drop-shadow-none xl:col-span-5 xl:col-start-2 ${contentClassName}`}
        >
          <h1 className="text-3.5xl font-bold leading-11 text-black-base lg:whitespace-pre-line lg:text-57 lg:leading-15">
            {title}
          </h1>
          <RichText
            contentData={description}
            className={`whitespace-normal pb-7 pt-3 text-15 text-black-base-light lg:whitespace-pre-line lg:pb-10 lg:pt-8 lg:text-lg ${richTextClassName}`}
          />
          <div
            id="heroPrimaryBtnContent"
            className="flex w-fit flex-col self-center text-center lg:flex-row lg:gap-6 lg:self-start "
          >
            <PrimaryButton
              id="heroPrimaryButton"
              heroBtn={true}
              sectionTitle={title}
              title={btnName}
              href={`${btnUrl}?${query}`}
              className="border-2 border-red-950 bg-red-950 px-4.5 py-2.5 text-lg font-bold text-white lg:px-6"
            />
            {phone ? (
              <PrimaryButton
                id="heroPhoneButton"
                sectionTitle={title}
                className="mt-3 border-2 border-blue-base px-6 py-2.5 text-lg font-bold text-blue-base lg:mt-0"
                title={`Call ${phone}`}
                href={`tel:${phone}`}
                heroBtn
              />
            ) : null}
          </div>
          {showTrustpilot ? (
            <TrustpilotItem
              starClassName="min-w-4.5 h-4.5 min-h-4.5 w-4.5 md:h-6 md:w-6 lg:h-7 lg:w-7"
              trustpilotRatingClassName="text-xs md:text-base md:pt-1.5 lg:pt-2.5"
              trustScoreClassName="text-sm lg:text-base"
              trustpilotClassName="text-sm md:text-lg lg:text-23"
              className="mt-4 lg:mb-8 lg:mt-12"
              starsGroupClassName="h-5 min-h-5 w-15 min-w-15 md:h-8 md:w-23 lg:h-6 lg:w-28 mx-1 lg:mx-3"
              starsGroupImgClassName="pt-1.5 md:pt-2 lg:pt-0.5 "
            />
          ) : null}
        </div>
        <div
          className={`relative col-span-full h-520 overflow-hidden lg:col-span-5 lg:col-start-8 lg:row-span-2 ${imgContainerClassName}`}
        >
          <Image
            src={img.src}
            alt={img.alt}
            className={`object-cover ${imgClassName}`}
            fill={true}
            priority={true}
            sizes="(max-width: 640px) 100vw, 50vw"
          />
        </div>

        {provenResult?.list ? (
          <div
            className={`col-span-full flex flex-col bg-blue-base text-center text-white lg:col-span-6 lg:bg-white lg:pt-9 lg:text-base xl:col-span-5 xl:col-start-2 ${provenResultsClassName}`}
          >
            <b className="px-4 text-2.5xl lg:hidden">
              Proven results over the years
            </b>
            <ProvenResultList list={provenResult.list} />
          </div>
        ) : null}
      </div>
      {provenResultsBelowTheFold ? (
        <ProvenResultsBelowTheFold data={provenResultsBelowTheFold} />
      ) : null}
    </>
  )
}

HeroSection.defaultProps = {
  btnName: 'Get debt help now',
  btnUrl: APPLY_FDR_URL(),
  provenResultsClassName: 'pt-24',
}

HeroSection.propTypes = {
  title: PropTypes.string.isRequired,
  description: PropTypes.object.isRequired,
  btnName: PropTypes.string,
  btnUrl: PropTypes.string,
  img: PropTypes.shape({
    alt: PropTypes.string.isRequired,
    src: PropTypes.string.isRequired,
  }).isRequired,
  provenResult: PropTypes.shape({
    list: PropTypes.array.isRequired,
    showInMobile: PropTypes.bool.isRequired,
  }),
  phone: PropTypes.string,
  heroContainerClassName: PropTypes.string,
  contentClassName: PropTypes.string,
  imgClassName: PropTypes.string,
  imgContainerClassName: PropTypes.string,
  showTrustpilot: PropTypes.bool,
  provenResultsBelowTheFold: PropTypes.object,
  provenResultsClassName: PropTypes.string,
}

export default HeroSection
