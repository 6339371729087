import RecommendedReadsItem from '../RecommendedReadsItem'
import React, { useCallback, useRef, useState } from 'react'
import useDeviceDetect from '../../../../../common/hooks/useDeviceDetect'
import classNames from 'classnames'
import SvgIcon from '../../../SvgIcon'

function CustomSlider({ data, isFullSizeMobile }) {
  const { isMobile } = useDeviceDetect()
  const [currentSlide, setCurrentSlide] = useState(0)
  const swipeStartX = useRef(0)
  const touchStartX = useRef(0)

  const handlePrevSlide = useCallback(() => {
    setCurrentSlide((prevSlide) => Math.max(prevSlide - 1, 0))
  }, [])

  const handleNextSlide = useCallback(() => {
    setCurrentSlide((prevSlide) => Math.min(prevSlide + 1, data.length - 1))
  }, [data.length])

  const handleTouchStart = useCallback((event) => {
    touchStartX.current = event.touches[0].clientX
  }, [])

  const handleTouchEnd = useCallback(
    (event) => {
      const touchDiff = event.changedTouches[0].clientX - touchStartX.current

      if (touchDiff > 50) {
        handlePrevSlide()
      } else if (touchDiff < -50) {
        handleNextSlide()
      }
    },
    [handleNextSlide, handlePrevSlide]
  )

  const handleMouseDown = useCallback((event) => {
    event.preventDefault()
    swipeStartX.current = event.clientX
  }, [])

  const handleMouseUp = useCallback(
    (e) => {
      const mouseSwipeDiff = e.clientX - swipeStartX.current
      if (mouseSwipeDiff > 50) {
        handlePrevSlide()
      } else if (mouseSwipeDiff < -50) {
        handleNextSlide()
      }
    },
    [handleNextSlide, handlePrevSlide]
  )

  return (
    <div
      data-testid="custom-slider-wrapper"
      className="h-auto w-full touch-pan-y lg:relative lg:grid lg:grid-cols-12"
      onTouchStart={handleTouchStart}
      onTouchEnd={handleTouchEnd}
      onMouseDown={handleMouseDown}
      onMouseUp={handleMouseUp}
    >
      <div className="h-full w-full overflow-hidden lg:col-span-full lg:col-start-1">
        <div
          className="mb-4 flex transition-transform duration-300 ease-out"
          style={{
            transform: ` translateX(-${
              currentSlide === data.length - 1
                ? currentSlide * (isMobile && isFullSizeMobile ? 100 : 55)
                : currentSlide * (isMobile ? (isFullSizeMobile ? 100 : 70) : 71)
            }%)`,
          }}
        >
          {data.map((item) => (
            <div
              key={item.id}
              className="h-full w-full"
              style={{
                minWidth: isMobile && isFullSizeMobile ? '100%' : '70%',
              }}
            >
              <RecommendedReadsItem
                {...item}
                isFullSizeMobile={isFullSizeMobile}
                isMobile={isMobile}
              />
            </div>
          ))}
        </div>
      </div>
      {!isMobile ? (
        <div
          data-testid="arrows wrapper"
          className="absolute top-1/2 col-start-2 mx-auto flex w-17/20 justify-between pl-5"
        >
          <button
            data-testid="prev-btn"
            className={classNames(
              'flex h-11 w-11 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow-md',
              { 'opacity-50': currentSlide === 0 },
              {
                'cursor-pointer text-blue-base opacity-100': currentSlide !== 0,
              }
            )}
            onClick={handlePrevSlide}
          >
            <SvgIcon
              name="chevronThinLeft"
              className={classNames('h-4', {
                'fill-blue-base': currentSlide !== 0,
              })}
            />
          </button>
          <button
            data-testid="next-btn"
            className={classNames(
              'flex h-11 w-11 -translate-y-1/2 items-center justify-center rounded-full bg-white shadow-md',
              { 'opacity-50': currentSlide === data.length - 1 },
              {
                'cursor-pointer text-blue-base opacity-100':
                  currentSlide !== data.length - 1,
              }
            )}
            onClick={handleNextSlide}
          >
            <SvgIcon
              name="chevronThinRight"
              className={classNames('h-4', {
                'fill-blue-base': currentSlide !== data.length - 1,
              })}
            />
          </button>
        </div>
      ) : null}
      {isMobile ? (
        <div data-testid="dots wrapper" className="flex justify-center">
          {data.map((item, index) => (
            <button
              key={item.id}
              data-testid="dot"
              className={classNames(
                'mx-2 h-1.5 w-1.5 rounded-full',
                { 'bg-blue-550': index === currentSlide },
                { 'bg-gray-970': index !== currentSlide }
              )}
              onClick={() => setCurrentSlide(index)}
            />
          ))}
        </div>
      ) : null}
    </div>
  )
}

export default CustomSlider
